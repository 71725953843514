// API utilities 
import axios from 'axios';

/**
 * Hit a terraform API endpoint and return the response.
 * 
 * @param {*} params Params to pass to the API
 * @param {*} endpoint Endpoint to hit
 */
export async function hit_terraform_api(params, endpoint) {
    // Add leading slash if not present
    if (endpoint[0] !== "/") {
        endpoint = "/" + endpoint;
    }

    return await axios({
        method: "post",
        url: process.env.VUE_APP_API_URL + endpoint,
        data: params,
    })
}

/**
 * Util to consolidate API function calls.
 * @param {*} endpoint Endpoint to hit
 * @param {*} data_key Key to pull data from 
 * @param {*} params Params to pass to the API endpoint.
 * @returns API response
 */
export async function api_consolidator(endpoint, data_key, params) {
    let response = await hit_terraform_api(params, endpoint);
    if (data_key == null) {
        return response.data
    } else {
        return response.data[data_key];
    }
}

/**
 * Util to consolidate API function calls, with an error handler and optional callback.
 * 
 * @param {*} endpoint Endpoint to hit
 * @param {*} data_key Key to pull data from 
 * @param {*} params Params to pass to the API endpoint.
 * @param {*} callback Optional callback to run upon completion. 
 * @returns 
 */
export async function api_consolidator_error_handler(endpoint, data_key, params, callback=null) {
    try {
        let response = api_consolidator(endpoint, data_key, params);
        if (callback) {
            return callback(response);
        }
        return response;
    }
    catch (error) {
        console.log("Error calling API endpoint: " + endpoint);
        console.error(error);
        return null;
    }
}

/**
 * Util to pack field_json into a JSON object.
 * 
 * @param {*} field_json Field JSON
 * @returns Packed JSON object
 */
export function field_json_args(field_json) {
    return {
        'field_json': field_json
    }
}

/**
 * Util to pack field_json and image_name into a JSON object.
 * 
 * @param {*} field_json Field JSON
 * @param {*} image_name Image name
 * @returns Packed JSON object
 */
export function field_json_image_name_args(field_json, image_name) {
    return {
        'field_json': field_json,
        'image_name': image_name
    }
}